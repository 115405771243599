import service from "@/plugins/request";

// 按月查询活动
export function apiCalendarQuery(params) {
  return service({
    url: "/v1/activity/query",
    method: "get",
    params: params
  });
}
// 分页查询活动日历
export function apiCalendarList(params) {
  return service({
    url: "/v1/protected/activity/list",
    method: "get",
    params: params
  });
}

// 日历活动列表
export function apiCalendarAdd(params) {
  let url = "/v1/protected/activity/add";
  if (params.id) {
    url = "/v1/protected/activity/edit";
  }
  return service({
    url: url,
    method: "post",
    data: params,
    successToast: true
  });
}
// 日历活动列表
export function apiCalendarDel(params) {
  return service({
    url: "/v1/protected/activity/delete",
    method: "post",
    data: params,
    successToast: true
  });
}

// 日历活动列表
export function apiProgressBar(params) {
  return service({
    url: "/calendar/progress-bar.html",
    method: "get",
    params: params
  });
}

// 活动查询
export function apiActivityGet(params) {
  return service({
    url: "/v1/activity/get",
    method: "get",
    params: params
  });
}
// 获取上线游戏
export function apiOnlineGame(params) {
  return service({
    url: "/v1/gameTimeLine/onlineGame",
    method: "get",
    params: params
  });
}
// 卡池分类列表
export function apiCardPoolKindList() {
  return service({
    url: "/v1/cardPoolKind/query-list",
    method: "get"
  });
}
// 添加卡池分类
export function apiAddCardPoolKind(params) {
  return service({
    url: "/v1/protected/cardPoolKind/add",
    method: "post",
    data: params
  });
}
// 删除卡池分类
export function apiDelCardPoolKind(params) {
  return service({
    url: "/v1/protected/cardPoolKind/delete",
    method: "post",
    data: params
  });
}
// 编辑卡池分类
export function apiEditCardPoolKind(params) {
  return service({
    url: "/v1/protected/cardPoolKind/edit",
    method: "post",
    data: params
  });
}
