<template>
  <div>
    <!-- 文本输入 -->
    <div
      ref="textBox"
      contenteditable="true"
      :placeholder="placeholder"
      class="article-edit-box"
      @focus="handleFocus"
      @input="handleInput"
      @keypress.ctrl.exact.prevent="onCtrlEnter"
      @keypress.enter.exact.prevent="onEnter"
    ></div>
    <!-- 工具栏 -->
    <div class="tool-bar">
      <div class="left-wrapper" :class="alias + '-left-wrapper'">
        <!-- 图片上传 -->
        <div v-if="showImg" class="img-update-btn">
          <i class="iconfont icon-tupian1"></i><input accept="image/*" type="file" @change="uploadImg" />
        </div>
        <!-- 视频上传 -->
        <div v-if="showVideo" class="video-update-btn">
          <i class="iconfont icon-shipin"></i><input accept="video/*" type="file" @change="uploadVideo" />
        </div>
      </div>
      <!-- 发布、评论按钮 -->
      <img
        v-if="alias === 'ba'"
        class="send-img-btn"
        src="@/assets/ba_imgs/send-btn.png"
        @click="_onOk"
        alt="发布"
        draggable="false"
      />
      <el-button v-else class="send-btn" size="mini" type="primary" @click="_onOk">{{ sendBtnText }}</el-button>
    </div>
    <!-- 图片、视频 -->
    <div v-if="resources.length" class="img-group">
      <div v-for="(item, index) in resources" :key="item.key" class="item">
        <div class="close-btn" @click="onCloseImg(index)">
          <i class="el-icon-close"></i>
        </div>
        <div v-if="item.percent > 0 && item.percent < 100" class="percent-box">{{ item.percent }}%</div>
        <i v-if="item.type == 'video'" class="el-icon-video-play"></i>
        <img v-if="item.url" :src="item.url" alt="" />
        <img v-else src="@/assets/images/local.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import sendMixins from "@/views/wiki/components/sendArticleBox/mixins";

export default {
  name: "sendArticleBox",
  mixins: [sendMixins]
};
</script>

<style lang="less" scoped>
.article-edit-box {
  width: 100%;
  height: 120px;
  border-radius: 4px;
  background: #f2f2f2;
  padding: 10px;
  font-size: 14px;
  user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  overflow: auto;
  border: none;
  resize: none;

  &:empty:before {
    content: attr(placeholder);
    color: #999;
  }
}

.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .left-wrapper {
    display: flex;
    align-items: center;
    &.ba-left-wrapper {
      .img-update-btn {
        background: url("~@/assets/ba_imgs/img-icon.png") no-repeat center / 100% 100%;
        i {
          display: none;
        }
      }
      .video-update-btn {
        background: url("~@/assets/ba_imgs/video-icon.png") no-repeat center / 100% 100%;
        margin-left: 6px;
        i {
          display: none;
        }
      }
    }

    .img-update-btn {
      width: 30px;
      height: 30px;
      position: relative;
      background-size: 100% 100%;
      border-radius: 4px;
      font-size: 24px;

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    .video-update-btn {
      width: 30px;
      height: 30px;
      position: relative;
      background-size: 100% 100%;
      margin-left: 20px;
      border-radius: 4px;
      font-size: 24px;

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  .send-img-btn {
    width: 52px;
    height: 30px;
  }
}

.img-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;

  .item {
    width: 60px;
    height: 60px;
    margin: 10px 10px 0 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .close-btn {
      position: absolute;
      top: -6px;
      right: -6px;
      z-index: 10;
      background: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px #ddd;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 0 6px #bbb;
      }
    }

    .percent-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
      background: fade(#000, 20%);
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 12px;
    }

    .el-icon-video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: #fff;
      text-shadow: 0 0 4px #bbb;
    }
  }
}
</style>
